import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "./Navbar.css";
import logo from "./Brightlystake-logo.svg";
function Navbars() {
  return (
    <>
      <header>
        <div class="container">

          <h1 class="logo">
          <a href="/moonbeam/Dashboard"></a>Brightlystake</h1>
          <nav>
            <ul>
              <li>
                <a href="/delegatorswithAPY">Delegators</a>
              </li>
              <li>
                <a href="/moonbeam/Dashboard">Moonbeam</a>
              </li>
              <li>
                <a href="/moonriver/Dashboard">Moonriver</a>
              </li>
              <li>
                <a href="https://moonbeam.brightlystake.com/">Stake with us</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Navbars;
