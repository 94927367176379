import React, { useState, useEffect } from 'react';
import { ApiPromise, WsProvider } from '@polkadot/api';
import './Progress.css';

function Progress() {
    const [current, setCurrent] = useState(0);
    const [end, setEnd] = useState(1800); // Set a default value for 'end'
    const [round_no, setRoundNo] = useState('');
    const [current_block, setCurrentBlock] = useState('');
    const [begin, setBegin] = useState('');


    useEffect(() => {

        async function fetchData() {
            try {

                const wsProvider = new WsProvider('wss://moonbeam.unitedbloc.com');
                const api = await ApiPromise.create({ provider: wsProvider });
                const round = await api.query.parachainStaking.round();
                const round_no = round['current'].toString();
                const beginning = round['first'].toString();
                const chain = await api.rpc.system.chain();
                const latest = await api.rpc.chain.getHeader();
                const end1 = parseInt(beginning) + 1800;
                setCurrent(latest.number - parseInt(beginning));
                setEnd(end1);
                setRoundNo(round_no);
                setCurrentBlock(latest.number.toString());
                setBegin(beginning)
                api.disconnect();

            } catch (error) {
                console.log('Exception in fetchData' + error)
            }
        }

        fetchData();
    }, []);

    const progressPercentage = (current / 1800) * 100;
    const blocksLeft = 1800 - current
    const totalMinutes = Math.floor((blocksLeft * 12) / 60).toFixed(1);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const timeLeftFormatted = `${hours} hours ${minutes} mins`;

        console.log(current + '-' + begin + '-' + blocksLeft)
    return (
        <div>
            <div>
                <p style={{ color: 'white' }}>
                    Current round : <span style={{ color: 'green' }}>  {round_no}</span>
                    Current Block : <span style={{ color: 'green' }}>  {current_block}</span>
                    Completes at : <span style={{ color: 'red' }}>  {end}</span>
                    Blocks left: <span style={{ color: 'red' }}>  {blocksLeft}</span>
                    Time left (Apprx):<span style={{ color: 'orange' }}>  {timeLeftFormatted}</span>
                </p>
            </div>
            <div className='container'>
                <div className="progress-container">
                    <div className="bar" style={{ '--progress': `${progressPercentage}%` }}></div>
                </div>
            </div>
        </div>
    );
}

export default Progress;
