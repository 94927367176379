import React from 'react'
import MoonbeamDashboard from "./MoonbeamDashboard";
import MoonbeamCollatorData from "./MoonbeamCollatorData";

function Moonbeam () {
  
    return (
      <div>
        <MoonbeamDashboard />
        <MoonbeamCollatorData />
        <p className="Disclaimer">If you encounter an issue please report to us</p>
      </div>
    )
  
}

export default Moonbeam;

