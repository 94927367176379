import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Moonbeam from "./Moonbeam";
import Moonriver from "./Moonriver";
import LandingPage from "./LandingPage";
import IndividualCollatorMoonbeam from "./IndividualCollatorMoonbeam";
import IndividualCollatorMoonriver from "./IndividualCollatorMoonriver";
import DelegatorsScreen from "./DelegatorsScreen";
import DelegatorsScreen1 from "./DelegatorsScreen1";
import Txns from "./Txns";
import Navbar from "./Navbars";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

ReactDOM.render(
  <Router>
    <React.Fragment>
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage/>} />
        <Route path="/delegators" element={<DelegatorsScreen/>} />
        <Route path="/delegatorswithAPY" element={<DelegatorsScreen1/>} />
        <Route path="/:type/analytics/:collatorId" element={<IndividualCollatorMoonbeam/>} />
        <Route path="/:type/analytics/:collatorId" element={<IndividualCollatorMoonriver/>} />
        <Route path="/moonriver/Dashboard" element={<Moonriver/>} />
        <Route path="/moonbeam/Dashboard" element={<Moonbeam/>} />
        <Route path="/moonbeam/quick-snap" element={<Txns/>} />
      </Routes>
    </React.Fragment>
  </Router>,
  document.getElementById("root")
);
