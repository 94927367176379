import React, { useState, useEffect } from 'react';
import { ApiPromise, WsProvider } from '@polkadot/api';
import './Progress.css';
import identity_file from './identity.json';
import StakingEvents from './StakingEvents'

function Txns() {
    const [sortedCollatorData, setSortedCollatorData] = useState([]);
    const [selectedCollators, setSelectedCollators] = useState(new Map());
    const [refreshInterval, setRefreshInterval] = useState(60); // Default interval in seconds
    const [collatorIdentities, setCollatorIdentities] = useState({});
    const [current, setCurrent] = useState(0);
    const [end, setEnd] = useState(1800); // Set a default value for 'end'
    const [round_no, setRoundNo] = useState('');
    const [current_block, setCurrentBlock] = useState('');
    const [begin, setBegin] = useState('');
    const [lastRefreshTime, setLastRefreshTime] = useState(Date.now());
    const [shortfallInterval, setShortfallInterval] = useState(1); // Default interval in rows

    const fetchData = async () => {
        try {
            const wsProvider = new WsProvider('wss://moonbeam.unitedbloc.com');
            const api = await ApiPromise.create({ provider: wsProvider });

            const currentStake = await api.query.parachainStaking.candidateInfo.entries();
            const selectedCollatorsResult = await api.query.parachainStaking.selectedCandidates();
            const round = await api.query.parachainStaking.round();
            const round_no = round['current'].toString();
            const beginning = round['first'].toString();
            const latest = await api.rpc.chain.getHeader();
            const end1 = parseInt(beginning) + 1800;

            const _selectedCollators = new Map(selectedCollatorsResult.toJSON().map(address => [address.toLowerCase(), true]));

            const collatorData = [];

            currentStake.forEach(([key, value]) => {
                const collatorAddress = key.args[0].toString().toLowerCase();
                const totalCounted = value.toJSON().totalCounted;
                const totalCountedFormatted = (parseInt(totalCounted, 16) / 1e18).toFixed(0);

                collatorData.push({ address: collatorAddress, stakedValue: totalCountedFormatted });
            });

            // Map identities to collatorData
            collatorData.forEach(collator => {
                collator.identity = identity_file[collator.address] || collator.address;
            });

            // Sort collatorData based on stakedValue in descending order
            collatorData.sort((a, b) => a.stakedValue - b.stakedValue);

            setSortedCollatorData(collatorData);
            setSelectedCollators(_selectedCollators);
            setCurrent(latest.number - parseInt(beginning));
            setEnd(end1);
            setRoundNo(round_no);
            setCurrentBlock(latest.number.toString());
            setBegin(beginning);

            setLastRefreshTime(Date.now()); // Update the last refresh time

        } catch (error) {
            console.log('Exception in fetchData' + error);
        }
    };

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, refreshInterval * 1000);

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [refreshInterval]);

    const progressPercentage = (current / 1800) * 100;
    const blocksLeft = 1800 - current;
    const totalMinutes = Math.floor((blocksLeft * 12) / 60).toFixed(1);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const timeLeftFormatted = `${hours} hours ${minutes} mins`;
    return (
        <div>
            <div>
                <p></p>
                <p></p>
                <p>Refresh Interval (seconds):</p>
                <p>
                    <input
                        type="number"
                        value={refreshInterval}
                        onChange={event => setRefreshInterval(event.target.value)}
                    />
                </p>
                <p>Last Refreshed: {new Date(lastRefreshTime).toLocaleString()}</p>
                <p>Shortfall Interval ( shortfall between n and n - x, x is what you enter):</p>
                <p>
                    <input
                        type="number"
                        value={shortfallInterval}
                        onChange={event => setShortfallInterval(event.target.value)}
                    />
                </p>

            </div>
            <p></p>
            <p></p>
            <div>
                <p style={{ color: 'white' }}>
                    Current round : <span style={{ color: 'green' }}>{round_no}</span>
                    Current Block : <span style={{ color: 'green' }}>{current_block}</span>
                    Completes at : <span style={{ color: 'red' }}>{end}</span>
                    Blocks left: <span style={{ color: 'red' }}>{blocksLeft}</span>
                    Time left (Apprx):<span style={{ color: 'orange' }}>  {timeLeftFormatted}</span>
                </p>
            </div>
            <div className='container'>
                <div className="progress-container">
                    <div className="bar" style={{ '--progress': `${progressPercentage}%` }}></div>
                </div>
            </div>
            <p></p>
            <p></p>
            <div>
                <div className="layout-container">


                    <div className="table-container">
                        <div className="table-responsive1">
                            <table>
                                <thead className="column-header">
                                    <tr>
                                        <th>Identity</th>
                                        <th>Counted Staked</th>
                                        <th>Shortfall</th> {/* New column */}
                                        <th>Active</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedCollatorData.map((item, index) => (
                                        <tr className="row" key={index}>
                                            <td className={(selectedCollators.has(item.address)) == true ? "Active" : "InActive"}>{item.identity}</td>
                                            <td>{item.stakedValue}</td>
                                            <td>
                                                {index >= shortfallInterval ? // Check if enough rows for comparison
                                                    (item.stakedValue - sortedCollatorData[index - shortfallInterval].stakedValue).toFixed(0)
                                                    : '-'
                                                }
                                            </td>
                                            <td className={(selectedCollators.has(item.address)) == true ? "Active" : "InActive"} >{selectedCollators.has(item.address) ? 'Active' : 'InActive'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* //Live stream of events */}
                    <div className="layout-container">
                        {/* <StakingEvents /> */}
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Txns;
